import { Physics, useBox, usePlane } from '@react-three/cannon';
import { Box as DreiBox, OrbitControls, Text } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { DefaultXRControllers, RayGrab, useXR, VRCanvas } from '@react-three/xr';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import * as THREE from "three";
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import './App.css';

const SvgShape = ({ shape, color, index }:{ shape: any, color: any, index: any}) => (
  <mesh>
    <meshLambertMaterial
      attach="material"
      color={color}
      /*
        HACK: Offset SVG polygons by index
        The paths from SVGLoader Z-fight.
        This fix causes stacking problems with detailed SVGs.
      */
      polygonOffset
      polygonOffsetFactor={index * -0.1}
    />
    <shapeBufferGeometry attach="geometry" args={[shape]} />
  </mesh>
)

const SvgAsync = React.memo(({ url, sceneRef }:{ url: string, sceneRef: any}) => {
  const { paths } = useLoader(SVGLoader, url)
  const shapes = useMemo(
    () =>
      paths.flatMap((path, index) =>
        path.toShapes(true).map(shape => ({ index, shape, color: path.color }))
      ),
    [paths]
  )
  return (
    <group
      ref={sceneRef}
      children={shapes.map((props, key) => (
        <SvgShape key={key} {...props} />
      ))}
      rotation={[-Math.PI / 2, 0, Math.PI]}
      scale={[-0.01, 0.01, 0.01]}
    />
  )
})

const DefaultModel = () => (
  <DreiBox args={[1, 1, 1]}>
    <meshBasicMaterial attach="material" color="hotpink" />
  </DreiBox>
)

const Svg = (props: any) => (
  <Suspense
    fallback={<DefaultModel {...props} />}
    children={<SvgAsync {...props} />}
  />
)
// // Geometry
// function GroundPlane() {
//   return (
//     <mesh receiveShadow rotation={[5, 0, 0]} position={[0, -1, 0]}>
//       <planeBufferGeometry attach="geometry" args={[500, 500]} />
//       <meshStandardMaterial attach="material" color="white" />
//     </mesh>
//   );
// }
// function BackDrop() {
//   return (
//     <mesh receiveShadow position={[0, -1, -5]}>
//       <planeBufferGeometry attach="geometry" args={[500, 500]} />
//       <meshStandardMaterial attach="material" color="white" />
//     </mesh>
//   );
// }
const defaultStartingPosition: [number, number, number] = [0, 1, -1]
function Box({ 
  url = './paywalls/hulu.jpeg',
  startingPosition = defaultStartingPosition,
}:{
  url?: string,
  startingPosition?: [number, number, number]
}) {
  const args: [number, number, number] = [0.9, 2,  0.25]
  const [ref, api] = useBox(() => ({ args, mass: 1, velocity: [0, 0, 0], position: startingPosition }));
  const paywall = useLoader(THREE.TextureLoader, url)

  return (
    <>
      <mesh onClick={() => {
        api.velocity.set(0, 2, 0);
      }} ref={ref} position={startingPosition} >
        <boxBufferGeometry args={args} attach="geometry" /> 
        <meshBasicMaterial attachArray="material" color="#171A1E" />
        <meshBasicMaterial attachArray="material" color="#171A1E" />
        <meshBasicMaterial attachArray="material" color="#171A1E" />
        <meshBasicMaterial attachArray="material" color="#171A1E" />
        <meshStandardMaterial map={paywall} attachArray="material" />
        <meshStandardMaterial map={paywall} attachArray="material" />
      </mesh>
    </>
  )
}

function Plane() {
  // const [ref] = useBox(() => ({ mass: 1 }));
  const [ref] = usePlane(() => ({ 
    mass: 0, 
    rotation: [-Math.PI / 2, 0, 0] 
  }));
  return (
    <mesh ref={ref} position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} >
      <planeBufferGeometry attach="geometry" args={[55, 55, 25, 25]}  />
      <meshPhongMaterial color="#222" attach="material" wireframe />
    </mesh>
  )
}

function PositionLoader() {

  const { player, isPresenting } = useXR()
  const [presentingOnce, setpresentingOnce] = useState<boolean>(false)
  useEffect(() => {
    if (!presentingOnce && isPresenting) {
      setpresentingOnce(true)
      player.position.set(0, 0, 5)
    }
  }, [isPresenting, player.position, presentingOnce])
  const [cameraTiltOnce, setCameraTilteOnce] = useState<boolean>(false)
  // useEffect(() => {
  //   if (!cameraTiltOnce) {
  //     setCameraTilteOnce(true)
  //     player.rotateZ(Math.PI * 0.5)
  //   }
  // }, [cameraTiltOnce, player])


  return (
    <></>
  )
}

function App() {
  

  return (
    <VRCanvas>
      <DefaultXRControllers />
      <OrbitControls />
      <PositionLoader />
      {/* <Stars /> */}
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 15, 10]} />
      <Physics>
        <Suspense fallback={null}>
        <Text
        position={[0, 2.5, -1]}
        scale={[3, 3, 3]}
        color="5DC6BD" // default
        anchorX="center" // default
        anchorY="middle" // default
      >
        PAYWALLSCREENS.COM
      </Text>
        <RayGrab>
          <Box />
        </RayGrab>
        <RayGrab>
          <Box url='./paywalls/fitnessai.jpeg' startingPosition={[2, 1, -1]}  />
        </RayGrab>
        <RayGrab>
          <Box url='./paywalls/alltrails.jpeg' startingPosition={[-2, 1, -1]}  />
        </RayGrab>
        </Suspense>
        <Plane />
      </Physics>
      {/* <BackDrop />
      <GroundPlane /> */}
      <color args={[0x000000] as any} attach="background" />
    </VRCanvas>
  );
}

export default App;
